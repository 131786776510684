import * as React from "react"
import Layout from "../components/layout"
import styled from "styled-components"
import { colors } from "../common/themeStyle"
import media from "styled-media-query"
import { StaticImage } from "gatsby-plugin-image"
import { SEO } from "../components/seo"

const IndexPage = () => {
  return (
    <Layout>
      <SEO />
      <StyledUpBox>
        <StaticImage src="../images/kv.jpg" alt="kv" />
      </StyledUpBox>
      <ContactWrapper>
        <StyledMiddleBox>
          <StyledNetabareHeadSentence>
            バーチャルを通し
            <LessThanMediumBreake />
            リアルの距離をなくす
            <LessThanMediumBreake />
            <GreaterThanMediumBreak />
            くまちょんを世界へ
          </StyledNetabareHeadSentence>
          <StyledNetabareSentence>
            あらゆるモノが身近になった21世紀、
            <GreaterThanMediumBreak />
            ついにモノとモノ、バーチャルとリアルの距離をなくすべく、
            <GreaterThanMediumBreak />
            くまちょんが世界を目指します。
          </StyledNetabareSentence>
          <StyledSectionTitle>ABOUT</StyledSectionTitle>
          <StyledNetabareSentence>
            株式会社くまちょんについて、知ってみませんか？
            <GreaterThanMediumBreak />
            あなたが知らなかった、新しいタイプのVTuberの
            <GreaterThanMediumBreak />
            魅力を発見するかもしれません
          </StyledNetabareSentence>
          <StyledGoClavesSiteButton
            onClick={() => {
              location.href = "about"
            }}
          >
            Read more
          </StyledGoClavesSiteButton>
          <StyledSectionTitle>RECRUIT</StyledSectionTitle>
          <StyledNetabareSentence>
            リアルの距離を縮めるべく、株式会社くまちょんで
            <GreaterThanMediumBreak />
            くまちょんと一緒に頑張ってくれる仲間を募集しています！
            <GreaterThanMediumBreak />
            Join us！！
          </StyledNetabareSentence>
          <StyledGoClavesSiteButton
            onClick={() => {
              location.href = "recruit"
            }}
          >
            Read more
          </StyledGoClavesSiteButton>
        </StyledMiddleBox>

        <StyledDownBox>
          <StyledDownBoxBackGround>
            <StyledSectionTitleContact>CONTACT</StyledSectionTitleContact>
            <ContactImageWrapper>
              <StaticImage
                onClick={() => {
                  location.href = "contact"
                }}
                src="../images/kumachon_contact.png"
                alt="kumachon contact"
                style={{ cursor: "pointer" }}
              />
            </ContactImageWrapper>
          </StyledDownBoxBackGround>
        </StyledDownBox>
      </ContactWrapper>
    </Layout>
  )
}

export default IndexPage

const ContactWrapper = styled.div`
  text-align: center;
  margin: 0 auto;
  max-width: 960px;
  padding: 0 1.0875rem 0;
`

const StyledUpBox = styled.div`
  margin-bottom: 50px;
  ${media.lessThan("medium")`
  margin-bottom: 20px;
  `}
`

const StyledMiddleBox = styled.div`
  margin-bottom: 10px;
`

const StyledDownBoxBackGround = styled.div``

const StyledDownBox = styled.div`
  background-color: ${colors.gray};
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  margin-top: 120px;
  display: flex;
  position: relative;
  flex-direction: column;
  -webkit-box-align: center;
  align-items: center;
  padding-top: 40px;
`

const StyledNetabareHeadSentence = styled.h1`
  line-height: 1.3;
  font-weight: 400;
  font-size: 48px;
  text-align: center;
  margin-bottom: 30px;
  ${media.lessThan("medium")`
  font-size: 30px;
  `}
`
const StyledNetabareSentence = styled.p`
  height: 42px;
  left: 428px;
  top: 778px;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 1.7;
  letter-spacing: 0.05em;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 80px;
  color: ${colors.black};
  ${media.lessThan("medium")`
  margin-bottom: 140px;
  font-size: 16px;
  `}
`

const StyledSectionTitle = styled.h2`
  letter-spacing: 0.07em;
  font-size: 35px;
  font-weight: bold;
  line-height: 1;
  color: rgb(51, 51, 51);
  width: 100%;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  &::before {
    border-top: 1px solid ${colors.yellow};
    content: "";
    -webkit-box-flex: 1;
    flex-grow: 1;
    margin-right: 80px;
  }
  &::after {
    border-top: 1px solid ${colors.yellow};
    content: "";
    -webkit-box-flex: 1;
    flex-grow: 1;
    margin-left: 80px;
  }
  ${media.lessThan("medium")`
  font-size: 16px;
  `}
`
const StyledSectionTitleContact = styled.h2`
  letter-spacing: 0.07em;
  font-size: 35px;
  font-weight: bold;
  line-height: 1;
  color: rgb(51, 51, 51);
  width: 100%;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  &::before {
    border-top: 1px solid ${colors.yellow};
    content: "";
    -webkit-box-flex: 1;
    flex-grow: 1;
    margin-right: 80px;
  }
  &::after {
    border-top: 1px solid ${colors.yellow};
    content: "";
    -webkit-box-flex: 1;
    flex-grow: 1;
    margin-left: 80px;
  }
  ${media.lessThan("medium")`
  font-size: 16px;
  `}
`

const ContactImageWrapper = styled.div`
  margin: 0 auto;
  width: 300px;
  padding: 10px;
  ${media.lessThan("medium")`
  width: 200px;
  `}
`

const StyledGoClavesSiteButton = styled.button`
  text-decoration: none;
  width: 100%;
  max-width: 500px;
  height: 60px;
  border: 0px;
  margin: 0 auto;
  background-color: ${colors.yellow};
  font-size: 20px;
  font-weight: bold;
  line-height: 1.35;
  letter-spacing: 2px;
  color: ${colors.black};
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  position: relative;
  margin-top: 60px;
  margin-bottom: 100px;
  &:hover {
    cursor: pointer;
  }
  ${media.lessThan("medium")`
  margin-bottom: 70px;
  `}
`

const GreaterThanMediumBreak = styled.br`
  ${media.lessThan("medium")`
    display: none;
  `}
`
const LessThanMediumBreake = styled.br`
  ${media.greaterThan("medium")`
    display: none;
  `}
`
